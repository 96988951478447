<template>
  <v-container class="p-4">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-3">
        Restricciones de solicitudes por unidad de negocio
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-data-table
      class="elevation-1 px-5 py-3 mt-4"
      :headers="tableHeaders"
      :items="requestRestrictions"
      :items-per-page="20"
      :loading="loadingRequestRestrisctions"
      loading-text="Cargando unidades"
      item-key="id"
      hide-default-footer
      no-data-text="Sin elementos"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.business_unit="{ item }">
        <p class="text-left my-0">
          {{ item.business_unit.name }}
          {{ item.is_cost_center ? "(CECO)" : "(Proyecto)" }}
        </p>
      </template>

      <template v-slot:item.quantity="{ item }">
        <v-text-field
          @focusout="updateQuantityRestriction($event, item)"
          class="pt-0 mt-0 w-25 mx-auto"
          single-line
          :value="item.quantity"
          :min="0"
          type="number"
          hide-details
        ></v-text-field>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import swal2 from "sweetalert2";
export default {
  mounted() {
    this.getRequestRestrictionsData();
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Restricciones de solicitudes",
          disabled: true,
          href: "/mantenedores/requestRestrictions",
        },
      ],
      debounce: null,
      debounceTime: 200,
      loading: false,
      loadingRequestRestrisctions: false,
      filters: {
        page: 1,
      },
      tableHeaders: [
        {
          text: "Unidad de negocio",
          align: "center",
          sortable: false,
          width: "25%",
          value: "business_unit",
        },
        {
          text: "Solicitudes urgentes",
          align: "center",
          sortable: false,
          value: "quantity",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getRequestRestrictions: "maintainers/getRequestRestrictions",
      updateRequestRestriction: "maintainers/updateRequestRestriction",
    }),
    async getRequestRestrictionsData() {
      this.loadingRequestRestrisctions = true;
      await this.getRequestRestrictions();
      this.loadingRequestRestrisctions = false;
    },
    async updateQuantityRestriction(event, item) {
      let value = event.target ? event.target.value : event;
      if (value) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(async function() {
          let payload = {
            restriction_id: item.id,
            quantity: value,
          };
          const resp = await self.updateRequestRestriction(payload);
          if (!resp.status || resp.status === 400) {
            self.loading = false;
            return swal2.fire({
              icon: "error",
              title: "Error",
              text: resp.data.detail,
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 10000,
              timerProgressBar: true,
            });
          }
          if (resp.status == 200) {
            swal2.fire({
              icon: "success",
              title: "Excelente",
              text: "Cantidad actualizada correctamente",
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 5000,
              timerProgressBar: true,
            });
          }
          if (resp.status == 500) {
            swal2.fire({
              icon: "success",
              title: "Excelente",
              text: "Error de servidor, intentalo nuevamente mas tarde",
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        }, self.debounceTime);
      }
    },
  },
  computed: {
    ...mapGetters({
      requestRestrictions: "maintainers/requestRestrictions",
    }),
  },
};
</script>

<style></style>
